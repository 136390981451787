import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import i18n from './language'
import './assets/style/font.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import SuffixImg from '@/components/SuffixImg.vue'
import '@/assets/index'
import svgIcon from '@/components/SvgIcon.vue'
import vue3GoogleLogin from 'vue3-google-login'
import mitt from 'mitt'
import pinia from './pinia'
import { SiteSet } from './utils/siteConfig'
import moment from 'moment'

const app = createApp(App)

declare module 'vue' {
  export interface ComponentCustomProperties {
    $Bus: typeof Mit
  }
}

app.config.globalProperties.$SiteSet = SiteSet()

const Mit = mitt()
app.config.globalProperties.$Bus = Mit

const currency = require('currency.js')

const getCurrency = (value) => {
  // let siteCountry = localStorage.getItem("siteCountry")
  let ccy = currency(value, {
    symbol: SiteSet().symbol,
    decimal: '.',
    separator: SiteSet().separator,
    precision: SiteSet().precision,
  }).format()
  return ccy
}

app.config.globalProperties.$ccy = getCurrency

app.config.globalProperties.$currency = {
  A: 'USD',
  B: '$',
}

app.config.globalProperties.$moment = moment
const mapKey = JSON.parse(localStorage.getItem('GetAppConfig'))?.googleMapKey || 'AIzaSyDdT9vHuR0sLb0ZYOZEgo6EWwDaNQBsuxU'
app.config.globalProperties.$mapKey = mapKey
app.use(vue3GoogleLogin, {
  clientId:
    '250157483079-4bajfjtnbuobiakpri5m6kai5mquep15.apps.googleusercontent.com',
})

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.component('suffix-img', SuffixImg).component('svg-icon', svgIcon)
app.use(router).use(ElementPlus).use(i18n).use(pinia).mount('#app')
