export default {
  '粘贴需要代购的中国购物网站的商品网址或者商品名称,例子:':
    'Please paste the URL or name of the item you want to purchase from a Chinese shopping website. For example:',
  信息: 'Information',
  请输入: 'Please enter',
  收货人: 'Recipient',
  联系电话: 'Contact number',
  邮政编码: 'Zip code',
  提示: 'Reminder',
  确定: 'Confirm',
  取消: 'Cancel',
  联系人: 'Contact person',
  地址: 'Address',
  不能为空: 'Cannot be empty',
  客服介入: 'Customer service intervention',
  录入包裹: 'Enter package',
  请选择: 'Please Select',
  三月内: 'Within 3 months',
  半年内: 'Within 6 months',
  一年内: 'Within one year',
  全部: 'All',
  催单成功: 'Reminder successful',
  已收到货: 'Goods received',
  最少6位: 'At least 6 digits',
  '支付完成后,请根据您支付的情况点击下面按钮':
    'After completing the payment, please click the button below according to your payment situation',
  支付遇到问题: 'Payment encountered a problem',
  支付完成: 'Payment completed',
  不能小于5或大于150个字符: 'Cannot be less than 5 or more than 150 characters',
  已保存: 'Saved',
  请选择充值金额: 'Please select the recharge amount',
  请输入用户名: 'Please enter username',
  请输入验证码: 'Please enter verification code',
  加入购物车中: 'Adding to shopping cart',
  继续: 'Continue',
  购物车详情: 'Shopping cart details',
  电话: 'Telephone',
  邮箱: 'Email',
  注册成功: 'Registered successfully',
  超出提交最大数量: 'Exceeds maximum submission limit',
  确认: 'Confirm',
  已删除: 'Deleted',
  关于乐达: 'About Loda',
  加入乐达: 'Join Loda',
  关于: 'About ',
  加入: 'Join ',
  关于我们: 'About us',
  服务协议: 'Service Agreement',
  服务支持: 'Service support',
  购物指南: 'Shopping Guide',
  如何下单: 'How to Place an Order',
  联系方式: 'Contact',
  站点查询: 'Site Search',
  工作时间: 'Working Hours',
  周一至周五: 'Monday to Friday',
  周六至周日: 'Saturday to Sunday',
  选择部门: 'Service support',
  无查询结果: 'No search results',
  筛选: 'Filter',
  价格: 'Price',
  首页: 'Home',
  '登 录': 'Log in',
  卖家中心: 'Seller Center',
  网站导航: 'Site Navigation',
  退出: 'Logout',
  海外购: 'Overseas purchasing',
  本地商城: 'Local Mall',
  店铺: 'Store',
  '粘贴需要代购的中国购物网站的商品网址或者商品名称、':
    'Paste the URL or name of the Chinese shopping website that needs to be purchased, for example',
  例子: 'Example',
  搜索店铺: 'Search Stores',
  支持商家: 'Support Merchants',
  阿里巴巴: '1688',
  淘宝: 'Taobao',
  天猫: 'Tmall',
  京东: 'Jingdong',
  拼多多: 'Pinduoduo',
  所有类别: 'All Categories',
  商品: 'Product',
  评价: 'Review',
  聊天回应率: 'Response rate',
  回应速度: 'Response speed',
  运输方式: 'Shipping method',
  包裹类目: 'Pickup warehouse',
  长度: 'Length',
  '(CM)': 'Centimeter',
  宽度: 'Width',
  高度: 'Height',
  重量: 'Weight',
  '(KG)': 'Kilo meter',
  数量: 'Quantity',
  查询: 'Query',
  运费估算: 'Shipping cost estimate',
  仓库地址: 'Warehouse address',
  包裹信息: 'Package information',
  新增包裹: 'Add package',
  快递单号: 'Tracking number',
  货物名称: 'Commodity name',
  货物数量: 'Quantity of goods',
  '保险服务(申报价值)': 'Insurance service (declared value)',
  最大货值金额: 'Maximum cargo value amount',
  保险费: 'Insurance fee',
  木架加固服务: 'Wooden frame reinforcement service',
  备注: 'Remarks',
  操作: 'Operation',
  查看禁运物品: 'View Prohibited Items',
  发货类型: 'Shipping Type',
  '请选择距您最近的提货仓直发包裹，您会更快的收到包裹。':
    'Please select the warehouse closest to you for direct shipping, you will receive your package faster',
  选择仓库: 'Select Warehouse',
  注意事项: 'Notes',
  我已阅读并同意: 'I have read and agree to',
  包裹转运验货规则和服务协议:
    'Package Transfer and Inspection Rules and Service Agreement',
  保险服务及条款: 'Insurance Services and Terms',
  修改: 'Modify ',
  提交: 'Submit',
  支付密码: 'Payment Password',
  请输入快递单号: 'Please enter the tracking number',
  按城市查询: 'Search by City',
  售后单号: 'After-sales Service Number',
  类型: 'Type',
  状态: 'Status',
  介入原因: 'Reason for Intervention',
  选择快递: 'Select Courier',
  上传凭证: 'Upload Certificate',
  关闭: 'Close',
  商品信息: 'Product Information',
  '退/换货数量': 'Quantity for Return/Exchange',
  单价: 'Unit Price',
  显示全部: 'Show All',
  原因: 'Reason',
  退款金额: 'Refund amount',
  返回设置: 'Return settings',
  登录密码修改成功: 'Login password changed successfully',
  支付密码修改成功: 'Payment password changed successfully',
  手机号修改成功: 'Phone number changed successfully',
  邮箱修改成功: 'Email changed successfully',
  新增收货地址: 'Add a new shipping address',
  有效期至: 'Valid until',
  发起配送: 'Initiate delivery',
  箱子号: 'Box number',
  请输入地址: 'Please enter address',
  流水号: 'Transaction number',
  运费: 'Shipping fee',
  客户代号: 'Code',
  提交时间: 'Submission time',
  预约取货时间: 'Submission time',
  体积: 'Size',
  催单: 'Urgent request',
  全选: 'Select all',
  支付: 'Payment',
  发起: 'Submit',
  订单号: 'Order number',
  售后中: 'After-sales in progress',
  取消订单: 'Cancel order',
  支付订单: 'Pay order',
  打印标签: 'Print label',
  取消标签: 'Cancel label',
  确认签收: 'Confirm receipt',
  待补款: 'Pending payment',
  发起售后: 'Initiate after-sales',
  '订单号/商品名称': 'Order number/product name',
  采购失败等待操作订单: 'Purchase failed, please try again later',
  金额: 'Amount',
  账户余额: 'Account balance',
  充值: 'Recharge',
  应付金额: 'Amount payable',
  交易密码: 'Transaction password',
  优惠劵: 'Coupon',
  线下充值: 'Offline recharge',
  汇入银行: 'Bank recharged',
  充值金额: 'Recharge Amount',
  充值时间: 'Recharge Time',
  充值证明: 'Recharge confirmation',
  支付宝充值: 'Alipay Recharge',
  币种: 'Currency',
  最小充值金额: 'Minimum Recharge Amount',
  最大充值金额: 'Maximum Recharge Amount',
  手续费: 'Service Fee',
  Wing充值: 'Wing Recharge',
  设为默认: 'Set as default',
  默认仓库: 'Default Warehouse',
  售后详情: 'After-sale Details',
  请输入姓名: 'Please Enter Name',
  手机号: 'Phone Number',
  请输入手机号: 'Please Enter Phone Number',
  所在地区: 'Location',
  选择地址: 'Select Address',
  详细住址: 'Detailed Address',
  请输入详细住址: 'Please Enter Detailed Address',
  地址照片: 'Address Picture',
  设为默认地址: 'Set as default',
  订单信息: 'Order information',
  充值信息: 'Recharge information',
  提货信息: 'Pickup information',
  是否删除: 'Delete or Not',
  查询时间: 'Query Time',
  清空: 'Clear',
  详情: 'Details',
  录单时间: 'Recording Time',
  商品数量: 'Product Quantity',
  提货方式: 'Pickup method',
  合并: 'Merge',
  已验证: 'Verified',
  物流状态: 'Logistics status',
  箱子内含件数: 'Number of items in the box',
  大陆出库时间: 'China delivery time',
  实重: 'Actual weight',
  计费重量: 'Chargeable weight',
  国际运费: 'International shipping fee',
  其他费用: 'Other fees',
  总金额: 'Total amount',
  用户名: 'Username',
  昵称: 'Nickname',
  用户代码: 'Code',
  生日: 'Birthday',
  性别: 'Sex',
  男: 'Male',
  女: 'Female',
  绑定业务员: 'Bind saleman',
  保存: 'Save',
  我的余额: 'My balance',
  提现: 'Withdraw',
  充值记录: 'Recharge record',
  提现记录: 'Withdrawal record',
  收支明细: 'Income and expenditure details',
  时间: 'Time',
  充值渠道: 'Recharge channel',
  提现卡号: 'Withdrawing card number',
  提现金额: 'Withdrawal amount',
  交易号: 'Transaction number',
  收入: 'Income',
  支出: 'Expenditure',
  余额: 'Balance',
  返回我的钱包: 'Back to my wallet',
  余额提现: 'Balance withdrawal',
  输入提现金额: 'Balance withdrawal',
  输入您的交易密码: 'Enter your transaction password',
  请选择出您提现的原因: 'Please select the reason for your withdrawal',
  可多选: 'Multiple selections allowed',
  上传身份信息: 'Upload identity information',
  验证身份: 'Verify identity',
  完成: 'Complete',
  验证码: 'Verification code',
  获取验证码: 'Get verification code',
  修改登录密码: 'Modify login password',
  请输入旧密码: 'Please enter the old password',
  旧密码: 'Old password',
  请输入新密码: 'Please enter a new password',
  新密码: 'New password',
  再次输入新密码: 'Re-enter the new password',
  设置交易密码: 'Set transaction password',
  验证方式: 'Verification method',
  设置支付密码: 'Set payment password',
  确认支付密码: 'Confirm payment password',
  页面维护中: 'Page under maintenance',
  实力: 'Strength',
  服务: 'Service',
  '完善的售后服务体系：时效保证、商品质量跟踪、退换货保障等':
    'Comprehensive after-sales service system: timeliness guarantee, product quality tracking, return and exchange guarantee, etc…',
  优势: 'Advantages',
  前往首页: 'Go to homepage',
  售后订单号: 'After-sales order number',
  售后订单: 'After-sales order',
  发起时间: 'Initiation time',
  快递公司: 'Courier company',
  商品图片: 'Product image',
  商品名称: 'Product name',
  话费充值: 'Mobile top-up',
  请输入以0开头的手机号码: 'Please enter a mobile phone number starting with 0',
  例如: 'Example',
  国际快递: 'International express',
  包裹查询: 'Package tracking',
  网点查询: 'Site Search',
  如何注册: 'How to register',
  如何充值: 'How to recharge',
  如何提现: 'How to withdrawal',
  '找到“免费注册”': 'Find "Free Registration"',
  填写注册信息: 'Fill out registration information',
  '①“用户名”必填,并且唯一。': '①"Username" is required and must be unique.',
  '② 设定登录密码,密码长度不能小于6位数。':
    '②Set up login password, which cannot be less than 6 digits.',
  '③“邮箱”与“手机号”非必填,若填写,则必须验证。':
    '③"Email" and "Mobile number" are optional, but if provided, must be verified.',
  '④“支付密码”选填,密码长度不能小于6位数。':
    '④"Payment password" is optional and cannot be less than 6 digits.',
  '⑤“业务员”（选填,推荐您的业务员工号,如果没有,可不填）。':
    '⑤"Salesperson" (optional, we recommend entering the salesperson ID. If you do not have one, you can leave it blank.)',
  勾选用户注册协议: 'Check the user registration agreement',
  '勾选用户注册协议，点击【用户注册协议】，可查看协议内容':
    'Check the user registration agreement and click on "User Registration Agreement" to view the agreement content.',
  查找商品: 'Searching for product',
  加入购物车: 'Add more chart',
  完成支付: 'Payment',
  进入充值页面: 'Enter the recharge page',
  '②点击“充值提现”,进入账户余额与明细页面':
    'Click on “Recharge and withdrawal” to enter the balance and details page',
  '③点击账户余额右侧“充值”,进入充值页面':
    'Click on the "recharge" button on the right side of the balance to enter the recharge page',
  充值成功: 'Recharge successful',
  进入提现页面: 'Enter the withdrawal page',
  '①登录之后,点击位于首页上方用户名,进入订单中心':
    'After logging in, click on the username above the homepage to enter the order center',
  '③点击账户余额右侧“提现”,进入提现页面':
    'Click on the "withdrawal" button on the right side of the balance to enter the withdrawal page',
  安全设置页面: 'Security settings page',
  '①在首页顶部点击您已登录的“用户名”':
    'Click on the "username" you logged in with at the top of the homepage',
  '②安全设置页面':
    'Complete the new password, verification password, and click on "Edit"',
  '①点击登录密码后面的“修改密码”':
    'Click on "Change Password" next to the login password',
  '②填写新的登录密码、确认登录密码，点击“修改”':
    'Fill in the new login password, confirm the login password, and click "Change"',
  '③密码修改完成': 'Password changed successfully',
  启用交易密码: 'Enable transaction password',
  '①点击交易密码后面的“立即启用”':
    'Click on "Enable Now" next to the transaction password',
  '③密码设置完成': 'Transaction password set successfully',
  公告: 'Announcement',
  店铺推荐: 'Recommended store',
  更多: 'More',
  账号登录: 'Login with account',
  手机登录: 'Login with mobile phone',
  账号: 'Account',
  密码: 'Password',
  记住用户: 'Remember me',
  忘记密码: 'Forgot password',
  免费注册: 'Register for free',
  第三方绑定: 'Third-party binding',
  绑定: 'Binding',
  小时内: 'Within hours',
  加入时间: 'Joining time',
  个月前: 'Months ago',
  全部商品: 'All products',
  下单时间: 'Order time',
  补款: 'Additional payment',
  收货人信息: 'Receiver information',
  手机号码: 'Phone number',
  配送信息: 'Delivery information',
  付款信息: 'Payment information',
  付款方式: 'Payment method',
  到齐发货: 'Ship when everything arrives',
  货到即发: 'Ship as soon as the goods arrive',
  是否取消: 'Cancel or not',
  取消补款: 'Cancel payment',
  补货款: 'Replenishment payment',
  小计: 'Subtotal',
  申请平台介入: 'Apply for platform intervention',
  撤销申请: 'Cancel application',
  已付金额: 'Amount paid',
  补款金额: 'Replenishment amount',
  正在审核: 'Under review',
  确认支付: 'Payment confirmation',
  确认收货: 'Receiving confirmation',
  合计: 'Total',
  留言: 'Remark',
  支付方式: 'Payment method',
  '店铺合计(含运费)': 'Total stores (including shipping)',
  店铺合计: 'Total stores',
  查看禁运品: 'Check prohibited items',
  '商品保险费(非人为破损100%退款)':
    'Product insurance (100% refund for non-artificial damage)',
  商品质量保障协议: 'Product quality assurance agreement',
  是否拆单: 'Split order or not',
  应付总额: 'Total amount payable',
  分享: 'Share',
  收藏商品: 'Favorite product',
  找同款: 'Find similar products',
  起批量: 'Minimum purchase quantity',
  '我们承诺您的交易安全:我们只会在买家收到货后拨款给卖家!':
    'We promises the safety of your transaction, we will only allocate to the seller after the customer receives the goods',
  配送至: 'Shipping to',
  该地址不支持配送: 'This address does not support shipping',
  商品来自: 'Product from',
  汇率: 'Exchange rate',
  库存: 'Stock',
  给客服留言: 'Leave a message for customer service',
  总额: 'Total amount',
  采购清单: 'Purchase list',
  已售完: 'Sold out',
  更新商品数据: 'Update product data',
  即时聊天: 'Live chat',
  进入商店: 'Enter store',
  推荐商品: 'Recommended products',
  商品已下架: 'The product is no longer available',
  '很抱歉，没有淘到该代购商品!':
    'Sorry, we could not find the product you are looking for',
  您可以仔细检查输入的代购链接是否正确:
    'You can check if the inputted purchasing link is correct',
  打开原链接: 'Open the original link',
  暂时不支持该链接: 'This link is currently not supported',
  设置新密码: 'Set a new password',
  验证: 'Verify',
  请再次输入新密码: 'Please re-enter your new password',
  新密码设置成功: 'New password set successfully',
  立即登录: 'Login immediately',
  登录密码: 'Password',
  请输入登录密码: 'Please enter your login password',
  再次输入登录密码: 'Enter your login password again',
  请再次输入登录密码: 'Please enter your login password again',
  请输入邮箱: 'Please enter your email',
  请输入交易密码: 'Please enter your transaction code',
  再次输入交易密码: 'Enter your transaction code again',
  请输再次输入交易密码: 'Please enter your transaction code again',
  业务员: 'Salesperson code',
  用户协议: 'Customer agreement',
  LODA隐私权政策: 'LODA privacy policy',
  ztoworld隐私权政策: 'ztoworld Legal Statement and Privacy Policy',
  的相关店铺: 'Related cooperating stores',
  已下架: 'Has been taken off the shelves',
  不满足起批量: 'Does not meet the minimum purchase quantity',
  '到底了!': 'End of results!',
  '购物车空空如也，赶紧挑选商品塞满你的购物':
    'Your shopping cart is empty, please choose more items to fill it up!',
  删除: 'Delete',
  all: 'All',
  清空购物车: 'Clear shopping cart',
  最多提交数量: 'Maximum number of submissions',
  已选: 'Selected',
  件: 'pcs',
  我的专属链接: 'My exclusive link',
  可用余额: 'Available balance',
  我的优惠卷: 'My discount coupons',
  热销榜: 'Hot Sales',
  低至: 'As low as',
  货物中心: 'Cargo center',
  资产中心: 'Asset center',
  我的优惠劵: 'My coupon',
  设置: 'Set',
  订单中心: 'Order Center',
  手机: 'Phone',
  '备注:以发往海外的时间开始计算，如有节假日，就顺延几天节假日时间。':
    'Remarks: The arrival time is calculated from when the items are sent from the China warehouse. If there are holidays, the arrival time will be delayed accordingly.',
  综合排序: 'Comprehensive Sort',
  销量升序: 'Ascending Sales',
  销量降序: 'Descending Sales',
  淘宝天猫: 'Taobao Tmall',
  新品: 'New Arrival',
  销量: 'Sales',
  全部订单: 'All Orders',
  待审核: 'Pending Review',
  待付款: 'Pending Payment',
  待订购: 'Pending Order',
  待发货: 'Pending Shipment',
  待签收: 'Pending Delivery',
  货在中国仓库: 'Goods in China Warehouse',
  已发往海外仓库: 'Sent to Overseas Warehouse',
  海外仓库已签收: 'Overseas Warehouse has Signed',
  客户已收货: 'Customer has Received',
  交易完成: 'Transaction Completed',
  作废订单: 'Cancelled Order',
  商城: 'Online Shop',
  话费: 'Phone Bill',
  等待付款: 'Waiting for Payment',
  已经付款: 'Payment Received',
  正常使用: 'Normal Use',
  已经使用: 'Used',
  已经过期: 'Expired',
  '登录密码能够保证您的账户安全，让您购物更放心':
    'Login password can ensure the security of your account and make you shop more confidently',
  '保障资金安全，提款需要设置提款密码':
    'To ensure fund security, setting a withdrawal password is required for withdrawal',
  '验证后，可用于快速找回登录密码及交易密码，接收账户余额变动提醒':
    'After verification, it can be used for quickly retrieving your login password and transaction password, as well as receiving account balance change notifications',
  '绑定邮箱后，可用于找回密码，接收相关邮件':
    'After binding your email, it can be used for password retrieval and receiving relevant emails',
  验证手机: 'Verify your mobile phone',
  验证邮件: 'Verify your email',
  绑定手机号: 'Bind your mobile phone number',
  修改手机号: 'Modify your mobile phone number',
  已认证手机号: 'Verified mobile phone number',
  绑定邮箱: 'Bind your email',
  修改邮箱: 'Modify your email',
  已认证邮箱: 'Verified email',
  欢迎使用LODA: 'Welcome to LODA',
  '这是柬埔寨最大电子商务平台，提供在线购物、外卖、物流快递、本地生活等生活服务，让您随时随地享受便捷生活。':
    'This is the largest e-commerce platform in Cambodia, providing online shopping, food delivery, logistics, local life services and more, allowing you to enjoy a convenient life anytime, anywhere.',
  '立即下载LODA APP': 'Get LODA app now!',
  热搜词: 'Hot Search',
  大家都在买: "Everyone's Buying",
  修改运输方式: 'Change shipping method.',
  仅自己可见: 'Visible only to self.',
  发往海外: 'Ship overseas.',
  等待收货: 'Awaiting delivery.',
  中中运费: 'Shipping cost in China.',
  等待审核: 'Waiting for review.',
  等待采购: 'Awaiting purchase.',
  付款成功: 'Payment successful.',
  商品出库: 'Product shipped.',
  '请按照示例上传图片，图片不正确或者不清楚都会导致审核过慢或者审核不通过':
    'Please upload images according to the example provided. Incorrect or unclear images may result in slow or failed review.',
  请输入正确手机号: 'Please enter a valid phone number.',
  我要购物: 'Reorder',
  继续充值: 'Continue top-up',
  'LODA柬埔寨-网上购物平台 | 批发&代购,国际转运&本地快递,外卖订餐,商城':
    'LODA Cambodia - Online Shopping Platform - Wholesale & Purchasing, International Shipping & Delivery Express, Food Delivery, Mall',
  重新下单: 'Order again',
  '本地(柬埔寨)': 'Local(Cambodia)',
  '海外(中国)': 'Overseas(China)',
  '提交成功!您的充值号为': 'Submitted successfully! Your recharge number is',
  大于库存: 'Greater than inventory',
  我的箱子: 'My Boxes',
  我的包裹: 'My Packages',
  LODA快递: 'LODA Express Delivery',
  '下载LODA APP：柬埔寨最佳在线购物平台':
    'Download LODA APP: Best Online Shopping Platform in Cambodia',
  搜索商品: 'Search Products',
  购物车: 'Shopping Cart',
  提交订单: 'Submit Order',
  新手引导: 'New User Guide',
  登录: 'Login',
  注册: 'Register',
  找回密码: 'Retrieve Password',
  帮助中心: 'Help Center',
  用户中心: 'User Center',
  订单详情: 'Order Details',
  我的订单: 'My Orders',
  收藏夹: 'Favorites',
  乐达配送: 'LODA Delivery',
  收货地址: 'Shipping Address',
  提货仓库: 'Pickup Warehouse',
  我的钱包: 'My Wallet',
  我的优惠券: 'My Coupons',
  个人资料: 'My Profile',
  安全设置: 'Security Settings',
  邀请好友: 'Invite Friends',
  消息通知: 'Notifications',
  已加入购物车: 'Added to shopping cart',
  '备注 : 以发往国际物流仓的时间开始计算,不包含中国采购时间,如果有节假日,就顺延几天节假日的时间':
    'Note: to the international logistics warehouse to start the calculation of time, does not contain the procurement time in China, if there are holidays, to extend the days of holiday time.',
  成功添加: 'Successfully added',
  'Loda是一个真实、可靠、便捷的在线购物平台。我们的服务让您真正实现网购无国界、无时差、无距离。在这里，您可以随时随地轻松网购各式各样的商品。':
    'loda is a real, reliable and convenient online shopping platform. With our services, you can shop online with no　boundary, no time difference and no distance. Herein, A collection of Chinese shopping websites and conumerous　products converged and you can shopping globally.',
  'Loda与中国多所知名商务机构合作，提供高品质的商品和服务，行业内最低的服务费收取标准。与多家国际快递合作，运费低廉，多种寄送方式。专业的采购团队和仓储管理，完整的客户服务体系。从购买到打包到运输，环环放心，完善的客户服务体系，及时解决您的需求。':
    'loda is a real, reliable and convenient online shopping platform. With our services, you can shop online with no　boundary, no time difference and no distance. Herein, A collection of Chinese shopping websites and conumerous　products converged and you can shopping globally.',
  '代购中国购物网站商品（loda帮您买），手机商城（掌上购物，一站式体验），高新科技的商务模式，专业电子商务人员，提供支持多平台多渠道的商品购买，24小时采购服务。':
    'Commitment on buying Chinese products (we help to buying for you), Mobile mall (Handheld shopping, Do as you wish), Modern web technology, Professional staffs of E-commerce, Plenty source of value goods, 24 Hours placing purchase order.',
  '新生儿的成长将会一路遇到许多挫折和坎坷，却总是在困惑中寻找正确的方向，在不断变化中创新和完善。 也许今天的我们并不是令人满意，但是我们会一直努力做个有态度的网购平台。 欢迎大家多多提出对我们的想法和建议，您的需求就是我们成长的动力，我们会不断完善，做个最好的自己。':
    'Newborn life means to grow up with full of frustration, from confused way to correct journey, from changing to progress, from novelty to perfect. Maybe we are not satisfactory now, your suggestion are welcomed and appreciated, and we can complete gradually. Your needs and focus will be our growing up power. Again, your suggestion are welcomed and appreciated.',
  分钟前: ' min ago',
  小时前: ' h ago',
  天前: ' d ago',
  购买过: 'Purchased',
  女装: "Women's Clothing",
  母婴: 'Maternity & Baby',
  美妆: 'Beauty & Personal Care',
  居家日用: 'Home & Daily Use',
  鞋品: 'Shoe',
  文娱车品: 'Entertainment & Automotive',
  数码家电: 'Digital & Electronics',
  男装: "Men's Clothing",
  内衣: 'Underwear',
  箱包: 'Bags & Luggage',
  配饰: 'Accessories',
  户外运动: 'Sports & Outdoors',
  家装家纺: 'Home Decor & Textiles',
  '到底啦~看看其他精选内容': 'No More Goods',
  '很抱歉，该商品为黑名单商品!': 'Sorry, the product is a blacklisted product!',
  商品名字: 'Product name',
  '您可以选择其他喜爱商品再次尝试，谢谢。':
    'Please choose other products, thank you.',
  收起: 'Fold up',
  取消原因: 'Cancellation Reason',
  确认删除吗: 'Are you sure to delete',
  '复制成功!快去粘贴发送给您的好友吧':
    'Copy successful! Go ahead and paste it to send it to your friends.',
  密码不一致: 'The passwords do not match.',
  '已发送验证码,请注意查收':
    'The verification code has been sent. Please check your messages.',
  默认地址: 'Default Address',
  是否跳转到包裹列表: 'Would you like to navigate to the package list?',
  已复制到粘贴板: 'Copied to clipboard',
  点击查看客服联系方式: 'Click to view customer service contact information',
  包邮: 'Free',
  您验证的邮箱: 'Your verified email',
  单件: 'Single Piece',
  集包: 'Bulk Packaging',
  请选择业务员: 'Please select salesman',
  '美味佳肴、超市便利': 'Delicious dishes, convenient shopping',
  '随时随点随到 尽在LODA APP':
    'Order anytime, anywhere, delivered instantly All in LODA app',
  服务费: 'Service charge',
  订购中: 'Ordering',
  '已入库（中国仓）': 'Received (China warehouse)',
  国际运输中: 'In international transportation',
  '已入库（印尼仓）': 'Received (Indonesian warehouse)',
  待收货: 'To be received',
  售后: 'After sales',
  请选择省份: 'Please select a province',
  未开通提货仓: 'Delivery warehouse not opened',
  请选择提货仓库: 'Please select a pickup warehouse',
  自营: 'Self operated',
  支付成功: 'Payment successful',
  自动跳转登录: 'Automatic redirect login',
  休息: 'Closed',
  数量必须是: 'The quantity must be a multiple of {name}',
  快递: 'Express delivery',
  微信充值: 'WeChat',
  交易密码已锁定:
    'The transaction password has been locked, please try again after {name}',
  不能为0: 'Cannot be 0',
  货物类型: 'Type of cargo',
  电子钱包: 'E - Wallets',
  线下支付: 'Offline payment',
  银行转账: 'Bank transfer',
  网上银行支付: 'Internet Banking',
  请设置提货仓: 'Please set up a pickup warehouse',
  配送费: 'Delivery Fee',
  '支持英文、数字、"-"、"."、"_"的组合，4-20个字符。不能纯数字，不支持邮箱': 'Supports English, numbers, "-", ".", "_" combination, 4-20 characters. Cannot be pure numbers, does not support email'
}
