// 谷歌接口翻译API 全局函数
export function translateApi(text, lan, type, mallId, keyType) {
  return new Promise(function (resolve, reject) {
    const localLan = localStorage.getItem('lang')
    const lang = lan || localLan
    // if(location.hostname=='www.loda.com.kh'&&type=='card') {
    // console.log(location.hostname)
    // 柬埔寨的都不翻译 type判断单独的是否翻译（弃用） || location.hostname=='localhost'
    if (location.hostname == 'www.loda.com.kh') {
      resolve(text)
      return false
    }
    if (
      (mallId != 111036 && localLan != 'zh-cn') ||
      (mallId == 121440 && localLan != 'pe')
    ) {
      var apiKey
      switch (keyType) {
        case 1:
          //搜索
          apiKey = 'AIzaSyC1yES8JDkCEFqQUt26Uso-Skx5f-7V5xg'
          break
        case 2:
          //标题
          apiKey = 'AIzaSyBX28DBzmhcCRTakC1zia6NcPLYH6ty0io'
          break
        case 3:
          //描述
          apiKey = 'AIzaSyDYP6WzVOafQP6WPPMhxaHPuXvTupg6_wc'
          break
        default:
          // apiKey="AIzaSyBX28DBzmhcCRTakC1zia6NcPLYH6ty0io"
          break
      }
      // const apiKey="AIzaSyBVm9ksJY-tT3AfuUf27XpNdFQLyCY45Mw"
      const url = `https://translation.googleapis.com/language/translate/v2?q=${encodeURIComponent(
        text
      )}&target=${lang}&key=${apiKey}`
      fetch(url)
        .then((result) => result.json())
        .then((res: any) => {
          resolve(res.data.translations[0].translatedText)
        })
        .catch((err) => {
          resolve(text)
        })
    } else {
      resolve(text)
    }
  })
}
