export function environment() {
  //设置环境
  let type = 1
  let thisEnvironment = {
    AppKey: '',
    secret: '',
    ServeUrl: '',
    type,
  }
  switch (type) {
    case 0: //正式环境
      thisEnvironment = {
        AppKey: '80254689',
        secret: 'BE54F141-36C2-4342-B965-07204F9C67A3',
        ServeUrl: 'https://api.loda.com.kh/20241204/',
        type,
      }
      break
    case 1: //开发环境
      thisEnvironment = {
        AppKey: '23534565',
        secret: '5482168584848484659598989',
        ServeUrl: 'https://devapi.loda.net.cn/',
        type,
      }
      break
  }
  return thisEnvironment
}
